<template>
	<div class="contact">
		<div class="contact__navbar">
			<Navbar />
		</div>
		<div class="contact__header" :style="{ 'background-color': primaryColor }">
			<h1 class="contact__header-title">{{ $t('router.contact') }}</h1>
		</div>
		<div class="contact__body" :style="{ 'background-image': 'url(' + image + ')' }">
			<div class="contact__body-sections">
				<!-- Aqui el componente -->
				<ContactComponent :primaryColor="primaryColor" />
			</div>
		</div>
		<footer class="contact__footer">
			<Footer />
		</footer>
	</div>
</template>

<script>
import Navbar from '@/components/navbar/Navbar.vue';
import Footer from '@/components/footer/Footer.vue';
import { getDocument, saveLogsAccess, receiveDateNow } from '@/api/common.js';
import ContactComponent from '@/components/contact/ContactComponent.vue';

export default {
	name: 'Contact',
	title() {
		return `${this.$t('router.contact')} | ${this.$store.state.eventInfo.evtrname}`;
	},
	components: {
		Navbar,
		Footer,
		ContactComponent
	},
	data() {
		return {
			image: ''
		};
	},
	mounted() {
		this.getImage();

		const user = JSON.parse(localStorage.getItem('pui9_session'));

		saveLogsAccess({
			loacfecha: receiveDateNow(),
			loacusr: user ? user.usr : 'anonymous',
			loacfuncionalidad: "Contacto",
			loaceventgroupid: this.$store.state.eventInfo.eveneventgroupid,
			loacurl: this.$route.path,
			loacip: user ? user.lastLoginIp : '0.0.0.0',
			loacenvironment: "CATÁLOGO",
			loacproductserviceid: 0,
			loacexhibitorid: 0
		});
	},
	computed: {
		primaryColor: function () {
			return this.$store.state.eventInfo.evenappprimarycolor
				? this.$store.state.eventInfo.evenappprimarycolor
				: this.$store.state.eventInfo.defaultColor;
		}
	},
	methods: {
		async getImage() {
			const data = await getDocument('event', this.$store.state.eventInfo.evenid, 'event_catalog_button_exhibitor');
			this.image = data[0].url;
		}
	}
};
</script>

<style lang="scss" scoped>
.contact {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__navbar,
	&__header,
	&__footer {
		flex: none;
	}
	&__body {
		overflow-y: hidden;
		flex: auto;
	}
}

.contact__header {
	background-color: #565567;
	height: 15vh;
	display: flex;
	align-items: center;
}

.contact__header-title {
	font-family: Cabin;
	font-weight: bold;
	font-size: 2rem;
	letter-spacing: 0.0625rem;
	color: #fff;
	padding-left: 10%;
	padding-top: 1.5rem;
}

.contact__body {
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}

.contact__body-sections {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 2rem;
	padding-bottom: 2rem;
}
</style>
