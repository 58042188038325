<template>
	<div class="contact-form">
		<div class="contact-form__header">
			<h2 class="contact-form__header-title">{{ $t('contact.contacttitle') }}</h2>
			<div></div>
		</div>
		<div class="contact-form__header contact-form__subheader">
			<p>
				{{ $t('contact.contactsubtitle') }}
			</p>
		</div>
		<form @submit.prevent="contact">
			<div class="contact-form__inputs">
				<v-text-field
					class="contact-form__input"
					:label="$t('contact.subject')"
					v-model="subject"
					:color="primaryColor"
					required
				></v-text-field>
				<v-textarea class="contact-form__input" :label="$t('contact.content')" v-model="content" :color="primaryColor" required></v-textarea>
			</div>
			<div class="contact-form__bottom">
				<v-btn class="contact-form__button" color="#2498ff" type="submit" depressed>
					{{ $t('common.accept') }}
				</v-btn>
			</div>
		</form>
	</div>
</template>

<script>
import { sendEmail } from '@/api/contact.js';

export default {
	name: 'LoginForm',
	props: {
		primaryColor: {
			type: String,
			default: '#d4af37'
		}
	},

	data() {
		return {
			subject: '',
			content: ''
		};
	},

	methods: {
		async contact() {
			const text = this.subject + '\n' + this.content;
			const data = await sendEmail(text, this.$store.state.eventInfo.evenid);
			if (data) {
				this.$puiNotify.success(this.$t('contact.contactsuccess'), this.$t('common.success'));
			} else {
				this.$puiNotify.error(this.$t('contact.contactsuccess'), 'Error');
			}
			this.subject = '';
			this.content = '';
		}
	}
};
</script>

<style lang="scss" scoped>
.contact-form {
	background: #fff;
	box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 0px 2px 3px rgba(0, 0, 0, 0.05);
	border-radius: 12px;
	padding: 2rem;
	width: 35rem;
}

.contact-form__header {
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;

	&-button {
		position: absolute;
		left: 0;
		width: 1rem;
		height: 1rem;

		&-icon {
			font-size: 1rem !important;
		}
	}

	&-title {
		font-family: Cabin;
		font-weight: bold;
		font-size: 1.625rem;
		color: #293133;
	}
}

.contact-form__inputs {
	padding-top: 1rem;
}

.contact-form__subheader {
	padding-top: 1rem;
}

.contact-form__input {
	width: 32rem;
}

.contact-form__bottom {
	display: flex;
	flex-direction: column;
}

.contact-form__button {
	color: #fff;
	margin: 1.5rem 0;
}
</style>
