import Pui9HttpRequests from 'pui9-requests';

export async function sendEmail(text, evenid) {
	var controller = `/eventcontact/insert`;
	let data = [];
	await Pui9HttpRequests.postRequest(
		controller,
		{
			evcoeventid: evenid,
			evcotext: text
		},
		response => {
			if (response && response.data) {
				data = response.data;
			}
		},
		error => {
			if (error) {
				data = error;
			}
		}
	);

	return data;
}
